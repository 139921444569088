<template>
    <div class="operationLog">
        <jy-query :model="formInline" ref="queryForm">
            <jy-query-item label="操作应用" prop="appId">
                <el-select v-model="formInline.appId" placeholder="请选择">
                    <el-option v-for="item in appOptions" :key="item.appId" :label="item.cName" :value="item.appId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="登录账号" prop="loginUser">
                <el-input v-model="formInline.loginUser" placeholder="输入账号"></el-input>
            </jy-query-item>
            <jy-query-item label="用户姓名" prop="loginName">
                <el-input v-model="formInline.loginName" placeholder="输入姓名"></el-input>
            </jy-query-item>
            <jy-query-item label="操作模块" prop="operatorModule">
                <el-input v-model="formInline.operatorModule" placeholder="输入操作模块"></el-input>
            </jy-query-item>
            <jy-query-item label="操作类型" prop="operatorType">
                <el-select v-model="formInline.operatorType" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="查询时间" prop="time" span="2">
                <el-date-picker v-model="formInline.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
            </template>
        </jy-query>
        <jy-table :data="lists">
            <jy-table-column type="index" width="60" label="序号"></jy-table-column>
            <jy-table-column label="登录账号" prop="loginUser" min-width="100"></jy-table-column>
            <jy-table-column label="用户姓名" prop="loginName" min-width="100"></jy-table-column>
            <jy-table-column label="所属机构" prop="orgName" min-width="150"></jy-table-column>
            <jy-table-column label="所属角色" prop="roleNames" min-width="100"></jy-table-column>
            <jy-table-column label="操作应用" prop="appName" min-width="100"></jy-table-column>
            <jy-table-column label="IP地址" min-width="180">
                <template slot-scope="scope">
                    {{scope.row |changeIP}}
                </template>
            </jy-table-column>
            <jy-table-column label="浏览器" prop="browser" min-width="100"></jy-table-column>
            <jy-table-column label="操作模块" prop="operatorModule" min-width="100"></jy-table-column>
            <jy-table-column label="操作类型" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.operatorType |changeType}}
                </template>
            </jy-table-column>
            <jy-table-column label="操作内容" prop="description" min-width="100"></jy-table-column>
            <jy-table-column label="操作时间" prop="operatorTime" min-width="180"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            formInline: {
                appId: '',
                loginUser: '',
                loginName: '',
                operatorModule: '',
                operatorType: '',
                time: '',
            },
            lists: [{}],
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '新增',
                },
                {
                    value: '2',
                    label: '修改',
                },
                {
                    value: '3',
                    label: '查看',
                },
                {
                    value: '4',
                    label: '删除',
                },
                {
                    value: '5',
                    label: '导入',
                },
                {
                    value: '6',
                    label: '导出',
                },
            ],
            appOptions: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
        }
    },
    created() {},
    activated() {
        this.getApplist()
    },
    filters: {
        changeIP(val) {
            if (val.operatorProvince) {
                return val.operatorIp + ' / ' + val.operatorProvince
            } else {
                return val.operatorIp
            }
        },
        changeType(val) {
            let v = ''
            switch (val) {
                case '1':
                    v = '新增'
                    break
                case '2':
                    v = '修改'
                    break
                case '3':
                    v = '查看'
                    break
                case '4':
                    v = '删除'
                    break
                case '5':
                    v = '导入'
                    break
                case '6':
                    v = '导出'
                    break
            }
            return v
        },
    },
    components: {},
    methods: {
        getApplist() {
            let url = '/log/queryPcApp'
            this.$http.post(url).then(res => {
                this.appOptions = res.detail
                this.formInline.appId = res.detail[0].appId
                this.getlist()
            })
        },
        getlist() {
            let url = '/log/queryOperationLog'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            if (option.time && option.time.length) {
                option.startTime = option.time[0]
                option.endTime = option.time[1]
            } else {
                option.startTime = ''
                option.endTime = ''
            }
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formInline.appId = this.appOptions[0].appId
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}
</script>
<style lang="scss" scoped="operationLog">
.operationLog {
}
</style>


